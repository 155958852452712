@use 'theme/common/variables.module.sass' as v
@use 'theme/common/deviceSize.module.sass' as device

// common
.paper
  background: transparent
  overflow: hidden
.root
  display: flex
  flex-direction: column
  background: #072433
  box-shadow: 7px 10px 20px #00000080
  border-radius: 3.75rem
  position: absolute
  top: 0
  bottom: 0
  width: 100%
  .title,
  .content,
  .functionFooter
    position: relative
    width: 100%
    display: flex
    justify-content: center
  .title
    color: #D9C998
    background: #002147 0% 0%
    z-index: 5
    width: 100%
    flex: 0 0 auto
    font-weight: bold
    display: flex
    justify-content: center
    align-items: center
    border-top-left-radius: 3.75rem
    border-top-right-radius: 3.75rem
  .content
    flex: 1
    overflow: hidden
    .scrollbarContent
      padding: 0 3rem
      .innerHtml
        padding: 1rem 0
    &::before,
    &::after
      content: ''
      width: 100%
      height: 2.25rem
      position: absolute
      left: 0
      z-index: 5
    &::before
      background: transparent linear-gradient(180deg, #02151D 0%, #02151D00 100%)
      top: 0
    &::after
      background: transparent linear-gradient(0deg, #02151D 0%, #02151D00 100%)
      bottom: 0
    .scrollbarFrame
      width: 100%
      position: relative
      .innerHtml
        overflow: hidden
        img
          max-width: 100%
        +device.mobile
          table
            width: 100%
            display: block
            overflow-x: auto
            white-space: nowrap

  .functionFooter
    display: flex
    align-items: center
    flex: 0 0 auto
    border-bottom-left-radius: 3.75rem
    border-bottom-right-radius: 3.75rem
    background: transparent linear-gradient(180deg, #0B396E 0%, #011327 100%)
    .pageBtnGroup
      position: absolute
      top: 0
      left: 0
      height: inherit
      display: flex
      button
        width: 9.375rem
        height: inherit
        cursor: pointer
        display: flex
        align-items: center
        padding: 1.5rem
        &.prevBtn
          justify-content: flex-end
          border-bottom-left-radius: 3.75rem
          .prevArrow
            margin-right: 1rem
            background-image: url('/image/btn/arrow_left.png')
        &.nextBtn
          margin-left: 1px
          justify-content: flex-start
          border-bottom-right-radius: 3.75rem
          .nextArrow
            margin-left: 1rem
            background-image: url('/image/btn/arrow_right.png')
        .prevArrow,
        .nextArrow
          width: 0.75rem
          height: 1.25rem
          background-size: contain
        .btnText
          font-size: 1.125rem
          color: #EFE8D4
    .shareButtonGroup
      position: absolute
      right: 10rem
    .closeBtn
      position: absolute
      right: 0
      width: 8rem
      height: inherit
      display: flex
      justify-content: flex-start
      align-items: center
      cursor: pointer
      &:hover
        opacity: 0.75
      .closeText
        color: #EFE8D4
        height: 1.125rem
        line-height: 1.25rem
        vertical-align: middle
      .closeIcon
        width: 1.25rem
        height: 1.25rem
        margin-left: 0.5rem
        background-image: url('/image/btn/Button_Close.png')
        background-repeat: no-repeat
        background-size: contain
// desktop
@media only screen and (min-width: v.$mobileWidth+1)
  .paper
    position: absolute
    top: 1rem
    bottom: 1rem
    max-width: 1000px
    border-radius: 3.75rem
  .root
    .title
      padding: 1rem 3rem
      min-height: 5.375rem
      font-size: 1.875rem
    .content
      padding: 0 1rem
    .functionFooter
      height: 4.375rem
      .pageBtnGroup
        button
          background: transparent linear-gradient(180deg, #1679C4 0%, #032041 100%)
          box-shadow: 6px 30px 40px #00000029
          &:hover
            background: transparent linear-gradient(0deg, #1679C4 0%, #032041 100%)
      .closeBtn
        .closeText
          font-size: 1.125rem
// mobile
@media only screen and (max-width: v.$mobileWidth)
  .paper
    position: absolute
    top: 3rem
    bottom: 8rem
    border-radius: 1.625rem
    min-width: 90%
  .root
    border-radius: 1.625rem
    .title
      padding: 1rem 2rem
      min-height: 8.375rem
      font-size: 2.25rem
      text-align: center
      border-top-left-radius: 1.625rem
      border-top-right-radius: 1.625rem
    .functionFooter
      height: 5.625rem
      border-bottom-left-radius: 1.625rem
      border-bottom-right-radius: 1.625rem
      .pageBtnGroup
        button
          background: transparent
          padding: 0.5rem
          .btnText
            font-size: 1.5625rem
          &.nextBtn
            margin-left: 1rem
      .closeBtn
        .closeText
          font-size: 1.5625rem
