.root
  background-image: url('/image/btn/btn_top02.png')
  background-repeat: no-repeat
  background-size: contain
  width: 4.5rem
  height: 4.5rem
  position: absolute
  top: 60vh
  right: 0.5rem
  transform: translateY(-50%)
  user-select: none
  z-index: 999

  &:hover
    background-image: url(/image/btn/btn_top02_hover.png)
    cursor: pointer
