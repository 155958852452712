@use 'theme/common/mixin' as mxn

@keyframes anim-sprite-sheet
  0%
    background-position-x: 0
  100%
    background-position-x: 100%
$z-fireworkGroup: 2

.root
  overflow: hidden
  position: fixed
  z-index: $z-fireworkGroup
  top: 0
  left: 50%
  transform: translateX(-50%)
  width: 100%
  height: 100vh
  max-width: 120rem
  pointer-events: none

  .firework_base
    position: absolute
    background-size: auto 100%
    background-image: url('/image/spriteSheet/firework.png')
    animation-name: anim-sprite-sheet
    animation-duration: 3s
    animation-timing-function: steps(49)
    animation-iteration-count: infinite
    +mxn.aspect-ratio(1, 1)

  .firework01
    width: 32.4375rem
    bottom: 25.875rem
    left: -13.5rem
    animation-duration: 2.625s
    animation-delay: 0.6375s
    filter: hue-rotate(0deg)

  .firework02
    width: 33rem
    bottom: 38.25rem
    right: 2.5rem
    animation-duration: 2.8735s
    animation-delay: 1.175s
    filter: brightness(1.3)
    filter: hue-rotate(15deg)

  .firework03
    width: 27.4375rem
    bottom: 12.25rem
    right: -10rem
    animation-duration: 2.9s
    animation-delay: 1.975s
    filter: brightness(1.4)
    filter: hue-rotate(0deg)
