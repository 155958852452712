%mainFrameResponsiveWidth
  display: flex

%sideTrackResponsiveWidth
  flex: 1 1 auto
  width: 14rem
  position: relative

%centerContentResponsiveWidth
  flex: 1 0 auto
  width: 93.75rem
  min-width: 750px
  max-width: 1500px

%sideTrackResponsiveWidthForWider
  flex: 1 1 auto
  width: auto
  position: relative

%centerContentResponsiveWidthForWider
  flex: 1 0 auto
  width: 81.25rem
  min-width: 750px
  max-width: 1440px

%responseSizeInner
  width: 100%
  position: absolute
  top: 0
  bottom: 0

%functionalButton
  font-size: 1.25rem
  font-weight: bold
  letter-spacing: 0.125rem