@use 'theme/common/variables.module.sass' as v

%center
  display: grid
  place-items: center

// common
.paper
  border-radius: 3.75rem
  background: none
.root
  min-width: 200px
  min-height: fit-content
  display: flex
  flex-direction: column
  background: #0E3E74 0% 0% no-repeat padding-box
  box-shadow: 7px 10px 20px #00000080
  border-radius: 3.75rem
  .title,.center,.bottom
    @extend %center
  .title,.bottom
    width: 100%
  .title
    color: #D9C998
    background: #002147 0% 0% no-repeat padding-box
  .content
    position: relative
    .text
      text-align: center
    .btn
      background: transparent linear-gradient(180deg, #31A7FF 0%, #053F51 100%) 0% 0% no-repeat padding-box
      border: 2px solid #1487DC
      border-radius: 60px
      opacity: 1
      text-align: center
      font: normal normal normal 16px/16px Microsoft JhengHei UI
      color: #FFFFFF
      &:disabled
        cursor: no-drop
    .form
      .label
        color: #D9C998
      .input
        color: #7CA6C5
    &::after
      content: ''
      width: 100%
      height: 1.2rem
      position: absolute
      left: 0
      z-index: 5
    &::after
      background: transparent linear-gradient(0deg, #01142380 0%, #0114230E 80%, #01142300 100%)
      bottom: 0
  .bottom
    background: transparent linear-gradient(180deg, #0B396E 0%, #011327 100%) 0% 0% no-repeat padding-box
    &:hover
      background: transparent linear-gradient(180deg, #F3B336 0%, #98570C 100%) 0% 0% no-repeat padding-box
    &:disabled
      cursor: no-drop
    &.disabled
      background: transparent linear-gradient(180deg,#2a3f56,#011020)
      color: #AAA
// mobile
@media only screen and (max-width: v.$mobileWidth)
  .root
    width: 85vw
    .title,.bottom
      height: 5rem
      font-size: 2rem
    .title
      position: relative
      .closeBtn
        height: 1.875rem
        position: absolute
        top: 1.6rem
        right: 3rem
        cursor: pointer
    .content
      padding: 1rem
      .text
        padding: 1rem
        letter-spacing: 0.64px
        font-size: 1.875rem
      .btn
        letter-spacing: 0.64px
        font-size: 1.875rem
        height: 3.125rem
        padding: 0.5rem 2.5rem
      .form
        padding: 1rem
        width: 100%
        >div
          font-size: 1.875rem
          width: 100%
          padding: 0.4rem
          >input
            width: 100%
            height: 3rem
            padding: 0.5rem
            font-size: 1.5rem
// desktop
@media only screen and (min-width: v.$mobileWidth)
  .root
    width: 100%
    .title,.bottom
      font-size: 22px
      height: 70px
      padding: 16px
      width: 100%
      letter-spacing: 0.76px
    .title
      user-select: none
      .closeBtn
        height: 20px
        position: absolute
        right: 35px
        top: 25px
        cursor: pointer
        &:hover
          filter: brightness(1.4)
    .content
      padding: 2rem 1rem
      .text
        padding: 15px
        letter-spacing: 0.64px
      .btn
        letter-spacing: 0.64px
        font-size: 16px
        height: 34px
        &:hover
          filter: brightness(1.4)
      .form
        display: grid
        place-items: center
        grid-template-rows: auto
        grid-template-columns: auto auto
        padding-top: 10px
        >div
          font-size: 16px
          padding: 4px 8px
        .label
          grid-column: 1
          justify-self: end
        .input
          grid-column: 2
          justify-self: start
          >input
            width: 235px
            padding: 8px
