@use 'theme/common/function.sass' as func
@use 'theme/common/variables.module.sass' as v
@use 'theme/common/mixin' as mxn
@use 'theme/desktop/extends'
@use 'theme/desktop/variables.module.sass' as vrbs

$desktopWidth: func.strip-unit(v.$desktopWidth)
$z-track: 1
$z-centerContent: 1
$z-headerBackground: 0

.root
  position: fixed
  top: 0
  left: 0
  z-index: 100
  width: 100%

  .toolbar
    @extend %mainFrameResponsiveWidth

    .leftTrack, .rightTrack
      @extend %sideTrackResponsiveWidth
      z-index: $z-track

    .centerContent
      @extend %centerContentResponsiveWidth
      z-index: $z-centerContent

      .headerContent
        display: flex
        justify-content: space-between
        align-items: center
        width: 100%

        .logo
          width: func.px2vw-lock(120, $desktopWidth)

          img
            width: 100%

        .toolbarItems
          display: flex
          align-items: center

    .headerBackground
      position: absolute
      z-index: $z-headerBackground
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: rgb(0,0,0)
      background: linear-gradient(180deg, rgba(0,0,0,0.07749037114845936) 0%, rgba(255,255,255,0) 100%)

      &::after
        content: ''
        position: absolute
        z-index: 1
        left: 0
        top: 0
        width: 100%
        height: 100%
        background: rgb(13,32,124)
        background: linear-gradient(180deg, rgba(13,32,124,1) 0%, rgba(3,15,51,1) 100%)
        opacity: 0
        transition: 0.25s

      &.isActive
        &::after
          opacity: 1
