@use "sass:math"

=responseSize($width, $height)
  position: relative
  width: 100%
  height: 0
  padding-bottom: math.div( $height, $width ) * 100%

=aspect-ratio($w, $h)
  &::after
    content: ''
    display: block
    padding-bottom: math.div($h, $w) * 100%