@use 'theme/desktop/variables.module.sass' as vrbs
@use 'theme/common/mixin' as mxn

.root
  width: 100%
  border-top-left-radius: 1rem
  border-top-right-radius: 1rem
  box-shadow: 0px 10px 15px #000000A3
  .appBar
    width: 100%
    .toolBar
      background-image: url('/image/menu_light.png'), url('/image/menu_light_reverse.png'), url('/image/menu_bg.png')
      background-size: 100% auto, 100% auto,cover
      background-position: 0 0, bottom -5% left 0, 0 0
      background-repeat: no-repeat
      border-top-left-radius: 1rem
      border-top-right-radius: 1rem
      padding: 0
      .toolbarItems
        display: flex
        justify-content: space-between
        width: 100%
        height: 4.5rem
