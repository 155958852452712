@use 'theme/mobile/variables.module.sass' as theme
.paper
  height: calc( 100% - #{theme.$bottomNavigationHeight} - #{theme.$headerHeight} )
  top: theme.$headerHeight
  background: transparent linear-gradient(0deg, #275188 0%, #011464 100%)
  background-repeat: no-repeat
  background-size: cover
  width: 25.6rem
.drawer
  height: 100%
  overflow-x: hidden
  justify-content: space-between
  display: flex
  flex-direction: column
  align-items: center
  .list
    flex-grow: 1
    width: 80%
    .listItems
      border-bottom: 1px solid rgba(126, 188, 224, 0.5)
      padding: 1.2rem
      .listItem
        color: #F7F7C2
        display: flex
        align-items: center
        .icon
          width: 2rem
          height: 2rem
        .label
          color: #F7F7C2
          margin: 0.8rem
          font-size: 2rem
        .arrowBtn
          width: 1.25rem
          height: 1.25rem
          transform: rotate(180deg)
          transition: all 0.3s ease-out
          &.reverse
            transform: rotate(0deg)
    .subListItems
      padding: 0 0 0 2rem
      .subListItem
        font-size: 1.875rem

.mediaGroup
  width: 80%
  font-size: 1.25rem
  display: flex
  align-items: center
  padding: 2rem 0
  .youtube
    background: #F40000
  .facebook
    background: #1773EA
  .line
    background: #07BC02
  .icon
    width: 50%
    height: 50%
  >a
    flex: 0 0 33%
    text-decoration: none
    >div
      height: 6rem
      flex: 1
      font-size: 1.25rem
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
