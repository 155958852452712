@use 'theme/common/mixin' as mxn

@keyframes GBao
  0%
    opacity: 0
  99%
    opacity: 0
  100%
    opactive: 1

@keyframes GBaoBase
  0%
    opacity: 1
  99%
    opacity: 1
  100%
    opactive: 0

@keyframes goldIngot01
  0%
    opacity: 1
    transform: translateY(-100%)
  16%
    opacity: 1
    transform: translateY(8.27%)
  28%
    opacity: 1
    transform: translateY(-13.12%)
  44%
    opacity: 1
    transform: translateY(4.63%)
  59%
    opacity: 1
    transform: translateY(-1.64%)
  73%
    opacity: 1
    transform: translateY(0.58%)
  88%
    opacity: 1
    transform: translateY(-0.2%)
  100%
    opacity: 1
    transform: translateY(0%)

@keyframes goldIngot02
  0%
    opacity: 1
    transform: translateY(-100%)
  12%
    opacity: 1
    transform: translateY(-89.11%)
  24%
    opacity: 1
    transform: translateY(-56.44%)
  36%
    opacity: 1
    transform: translateY(-1.99%)
  54%
    opacity: 1
    transform: translateY(-24.97%)
  74%
    opacity: 1
    transform: translateY(-1.63%)
  82%
    opacity: 1
    transform: translateY(-6.25%)
  92%
    opacity: 1
    transform: translateY(-0.66%)
  96%
    opacity: 1
    transform: translateY(-1.54%)
  100%
    opacity: 1
    transform: translateY(0%)

@keyframes neonLight
  0%
    opacity: 0
  15%
    opacity: 0
  25%
    opacity: 1
  30%
    opacity: 0
  80%
    opacity: 0
  85%
    opacity: 1
  90%
    opacity: 0
  95%
    opacity: 1
  100%
    opacity: 1

.root
  +mxn.responseSize(480, 302)
  a
    position: absolute
    width: 100%
    top: 0
    bottom: 0
  .GBaoLogo
    width: 100%
    height: 100%
    >div
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: transparent
      background-size: 100% 100%
    .GBao
      background-image: url('/image/GBao-Logo.png')
      opacity: 1
      animation-name: GBao
      animation-duration: 1.5s
    .GBaoBase
      background-image: url('/image/GBao-Logo_01.png')
      opacity: 0
      animation-name: GBaoBase
      animation-duration: 1.5s
    .goldIngot
      background-image: url('/image/GBao-Logo_02.png')
      opacity: 0
      animation-name: goldIngot02
      animation-duration: 1s
      animation-delay: 0.5s
    .neonLight
      background-image: url('/image/GBao-Logo_light.png')
      opacity: 1
      animation: neonLight 2s linear
