$fontSize: 1.125rem
$fontFamily: Verdana, Microsoft JhengHei UI
$textColor: #ffffff
$primaryColor: #7ebce0
$secondaryColor: #73a092
$errorColor: #cb492a
$menubarHoverFontColor: #02243F
$footerFontColor: #739AB8
$headerHeight: 4rem
$backdropBackground: rgba(#7CA6C5, 0.54)

:export
  fontSize: $fontSize
  fontFamily: $fontFamily
  textColor: $textColor
  primaryColor: $primaryColor
  secondaryColor: $secondaryColor
  errorColor: $errorColor
  menubarHoverFontColor: $menubarHoverFontColor
  backdropBackground: $backdropBackground
