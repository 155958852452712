.root
  width: 100%
  // height: 100vh
  justify-content: center
  display: flex
  .container
    max-width: 500px
    height: 100vh
    margin-top: 12rem
  .title
    height: 70px
    background: #002147
    padding: 2rem
    border-radius: 60px 60px 0px 0px
    text-align: center
    font-size: 18px
    color: #d9c998
    display: flex
    align-items: center
    justify-content: center
  .content
    width: 100%
    max-width: 550px
    height: 100%
    max-height: 220px
    background: #0e3e74
    text-align: center
    padding-top: 10px
    position: relative
    .contentBottom
      position: absolute
      width: 100%
      height: 1.2rem
      background: transparent linear-gradient(0deg, #01142380 0%, #0114230E 80%, #01142300 100%) 0% 0% no-repeat
      bottom: 0
    > div:first-child
      margin-top: 1rem
    img
      width: 56px
      height: 56px
    .text
      font-size: 16px
      margin: 5px 0 10px 0
      padding: 0 5px
      p
        margin: 0
    .btn
      display: flex
      align-items: center
      justify-content: center
      .notFullBtn
        background: #E10000
        text-decoration: none
        &:hover
          background: #CF0000
      .fullBtn
        background: #00BF47
        margin-left: 30px
        &:hover
          background: #009A38
      button, .notFullBtn
        width: 102px
        height: 34px
        border: 1px solid #001B2C
        border-radius: 5px
        font-size: 14px
        display: flex
        align-items: center
        justify-content: center
        font-family: Verdana, Microsoft JhengHei UI
  .footer
    background: transparent linear-gradient(180deg, #0B396E 0%, #011327 100%) 0% 0% no-repeat padding-box
    height: 70px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 0px 0px 60px 60px
    .hint
      display: flex
      justify-content: center
      align-items: center
      font-size: 16px
      input
        width: 14px
        height: 14px
        margin-right: 5px
