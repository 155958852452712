@use 'theme/common/deviceSize.module.sass' as device

.root
  width: auto
  height: 100%
  font-size: 1rem
  flex: 1 1 auto
.tooltip
  background: #0E71C9EE
  padding: 0.8rem 1rem
  +device.desktop
    font-size: 1rem
  +device.mobile
    font-size: 1.5rem
.tooltipArrow
  color: #0E71C9EE
