@use 'theme/common/function.sass' as func
@use 'theme/common/variables.module.sass' as v
@use 'theme/common/mixin' as mxn
@use 'theme/desktop/theme.module.sass'

$desktopWidth: func.strip-unit(v.$desktopWidth)
$z-bottomBG: 0

.root
  display: flex
  position: relative
  justify-content: center
  width: 100%
  overflow: overlay
  background: #300e3e

  .view
    max-width: 1920px
    min-width: 981px
    width: 100%
    min-height: 100vh
    background-image: url('/image/bottom_bg.jpg')
    background-repeat: no-repeat
    background-attachment: fixed
    background-position: bottom
    background-size: cover
    overflow: hidden

    > :not(:first-child):last-child
      margin-top: 3.125rem

  .bottomBG
    width: 100%
    position: absolute
    z-index: $z-bottomBG
    left: 0
    bottom: 0
    +mxn.aspect-ratio(1920, 1080)

  .vague
    filter: blur(12px)
