@use 'theme/common/deviceSize.module.sass' as device

// common
.paperWidthFalse
  width: 100%
  background: none
  box-shadow: none
  margin: 0 25%
  overflow-y: initial
  +device.mobile
    margin: 0 5% !important
.root
  min-width: 200px
  min-height: fit-content
  display: flex
  flex-direction: column