@use 'theme/mobile/variables.module.sass' as mobileTheme
.root
  width: 100%
  display: flex
  height: mobileTheme.$bottomNavigationHeight
  background: transparent linear-gradient(180deg, #0DB9DE 0%, #0E71C9 23%, #004AA8 100%) 0% 0% no-repeat padding-box
  opacity: 0.95
  position: fixed
  bottom: 0
  left: 0
  z-index: 1400
  a,
  button
    text-decoration-line: none
    flex: 1 1 auto
    .button
      display: flex
      height: 100%
      font-size: 2rem
      align-items: center
      justify-content: center
      border: 1px solid #59D8E6
    .title
      font-size: 2rem
      margin-left: 0.5rem
      color: #FFF
    .customerService
      width: 2.25rem
      height: 2.5rem
    .deposit
      width: 2.5rem
      height: 2.5rem
    .download
      width: 3.3rem
      height: 2rem
  button
    padding: 0
    background: transparent
