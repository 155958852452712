@use 'theme/common/variables.module.sass' as v
@use 'theme/common/deviceSize.module.sass' as device

.root
  display: flex
  justify-content: space-between
  >button
    margin: 0
  .shareBtn
    width: auto
    height: 2rem
    font-size: 1rem
    text-decoration-line: none
    margin-right: 0.6rem
    cursor: pointer
    .facebookBtn,
    .lineBtn,
    .copyLinkBtn
      position: relative
      width: 100%
      height: 100%
      border-radius: 1.25rem
      padding: 0.5rem 1.2rem
      display: flex
      justify-content: center
      align-items: center
      img
        margin-right: 0.2rem
        width: 1.2rem
        height: 1.2rem
      .text
        font-size: 1rem
        line-height: 1rem
    .facebookBtn
      background-color: #1876F0
      &:hover
        background-color: rgba(#1876F0, 0.6)
    .lineBtn
      background-color: #00B832
      &:hover
        background-color: rgba(#00B832, 0.6)
    .copyLinkBtn
      background-color: #2E5674
      &:hover
        background-color: rgba(#2E5674, 0.6)
// mobile
@media only screen and (max-width: v.$mobileWidth)
  .root
    .shareBtn
      width: 2.375rem
      height: 2.375rem
      border-radius: 50%
      margin-right: 1.2rem
      >div
        >img
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          margin-right: 0
    .text
      display: none
