:global(.error)
  letter-spacing: 1.12px
  color: #FFCA29
  padding: 0px !important
:global(button)
  border-width: 0
:global(input)
  background: #FAFAFA 0% 0% no-repeat padding-box
  border: 1px solid #032041
  border-radius: 0.5rem
button
  cursor: pointer
  &:disabled
    cursor: no-drop
    filter: brightness(0.5)
:global(.grecaptcha-badge)
  visibility: hidden
:global(.fb_dialog)
  z-index: 1499 !important

:global(.relative)
  position: relative

:global(.image_obj)
  position: absolute
  width: 100%
  height: 100%
  top: 0
  left: 0
  bottom: 0
  right: 0

  img
    width: 100%
    height: 100%

