@use 'theme/common/variables.module.sass' as v
.bottom
  width: 100%
  display: flex
  .btn
    width: 100%
    display: grid
    place-items: center
    font-size: 20px
    // mobile
    @media only screen and (max-width: v.$mobileWidth)
      font-size: 1.875rem
.msgText
  color: #FFCA29 !important
  // mobile
  @media only screen and (max-width: v.$mobileWidth)
    font-size: 1.75rem
