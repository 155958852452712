@use 'theme/common/extends'

.loadingImg
  @extend %loadingBackdrop

.root
  .component,
  .text
    width: auto
    max-width: 80%
