@use 'theme/common/variables.module.sass' as v
.text
  text-align: center
  letter-spacing: 1.2px
  color: #F7F7C2
  opacity: 1
  padding: 15px
  font-size: 30px !important

// mobile
@media only screen and (max-width: v.$mobileWidth)
.text
  padding: 1rem
  font-size: 2.5rem
