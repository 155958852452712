@use 'theme/desktop/variables.module.sass' as vrbs
@use 'theme/desktop/extends'

%footerFont
  font-size: 0.9375rem
  color: vrbs.$footerFontColor
.root
  width: 100%
  position: relative
  padding: 1.25rem 0 2rem 0
  bottom: 0
  left: 0

  @extend %mainFrameResponsiveWidth
  .leftTrack,
  .rightTrack
    @extend %sideTrackResponsiveWidth
  .centerContent
    @extend %centerContentResponsiveWidth
    display: flex
    flex-direction: column
    align-items: center
    .vendor
      align-self: center
      width: 15rem
      img
        max-width: 100%
    .email
      padding: 0.5rem 0
      @extend %footerFont
    .footerInfo
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: center
      width: 60rem
      min-width: 650px
      max-width: 950px
      .announcement
        flex: 1 1 auto
        width: 80%
        @extend %footerFont
      .relatedLinks
        width: 8rem
        height: auto
        margin-left: 1rem
      .rating
        width: 3.75rem
        height: 3.75rem
        margin-left: 1rem
        img
          max-width: 100%
          height: auto
    .reCaptchaInfo
      @extend %footerFont
      width: 100%
      text-align: left
      margin: 0.5rem 0
      >a
        text-decoration: none
        color: #1F71AF
    .copyRight
      @extend %footerFont
      width: 60rem
      min-width: 650px
      max-width: 950px
