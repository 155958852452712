@use 'theme/mobile/theme.module.sass'
@use 'theme/mobile/variables.module.sass' as mobileTheme
.root
  width: 100%
  overflow: overlay

  .view
    min-height: 100vh
    background-repeat: no-repeat
    background-size: contain, contain
    background-position: 0 28rem, bottom 0 left 0
    background-attachment: local, fixed
    width: 100%
    position: relative

    &::before
      content: ''
      width: 100%
      height: 100vh
      display: block
      position: fixed
      background: url('/image/bottom_bg_m.jpg')
      background-repeat: no-repeat
      background-size: cover
      background-position: 0 0
      z-index: 0

  .vague
    filter: blur(12px)
