.root
  a
    width: 60px
    height: 60px
    background-color: #0A7CFF
    display: flex
    justify-content: center
    align-items: center
    border-radius: 60px
    position: fixed
    bottom: 30px
    right: 18px
    z-index: 999999
    svg
      display: flex
      margin: auto
      position: relative
      margin-top: 12px
  .vague
    filter: blur(12px)