@use 'theme/common/function.sass' as func
@use 'theme/common/variables.module.sass' as v
@use 'theme/common/mixin' as mxn

//common
.primaryColor
  color: #DFC78F

.card
  background: rgb(3, 32, 65, 0.7) 0% 0% no-repeat padding-box
  border-radius: 1.25rem
  padding: 0
  margin: 0
  display: flex
  flex-direction: column

  .content
    padding: 1rem

    .column
      display: flex
      justify-content: space-between
      align-items: center

      > div
        display: flex
        justify-content: space-between
        align-items: center

    .btn
      margin-top: 1rem
      background: transparent linear-gradient(180deg, #FED075 0%, #98570C 100%) 0% 0% no-repeat padding-box
      border-radius: 0.625rem
      color: #EFE8D4
      width: 100%
      display: grid
      padding: 0.7rem
      place-items: center
      text-shadow: 1px 1px 6px #3A2700F7
      font-weight: bold

      &:hover
        background: url('/image/btn/cion_arrow.png')
        background-repeat: no-repeat
        background-size: 100% 100%
        cursor: pointer

    .underLine
      opacity: 0.26
      background: #FFFFFF
      height: 1px
      width: 100%
      margin: 0.1rem 0

  .bottom
    background: transparent linear-gradient(180deg, #0B396E 0%, #011327 100%) 0% 0% no-repeat padding-box
    border-radius: 0px 0px 1.25rem 1.25rem
    padding: 1rem
    color: #FFFFFF
    text-shadow: 0px 1px 1px #000000E3
    cursor: pointer

    &:hover
      background: transparent linear-gradient(180deg, #F3B336 0%, #98570C 100%) 0% 0% no-repeat padding-box

    &:disabled
      cursor: no-drop

// desktop
@media only screen and (min-width: v.$mobileWidth+1)
  .root
    $desktopWidth: func.strip-unit(v.$desktopWidth)
    position: relative
    cursor: pointer

    &:hover
      .notLoggedInMemberBG
        background-image: url("/image/btn/member_bg_hover.png")

      .isLoggedInMemberBG
        background-image: url("/image/btn/member_login_bg_hover.png")

    .notLoggedInMemberBG
      position: relative
      background-image: url("/image/btn/member_bg.png")
      background-repeat: no-repeat
      background-size: contain
      background-position: center
      width: func.px2vw-lock(240, $desktopWidth)
      +mxn.aspect-ratio(240, 80)
      transition: 0.15s

    .isLoggedInMemberBG
      position: relative
      background-image: url("/image/btn/member_login_bg.png")
      background-repeat: no-repeat
      background-size: contain
      background-position: center
      width: 19.375rem
      +mxn.aspect-ratio(310, 80)
      transition: 0.15s

    .notLoggedInWrapper
      position: absolute
      top: 0
      left: 0
      z-index: 1
      width: 100%
      height: 100%
      display: flex
      flex-direction: row
      justify-content: flex-start
      align-items: center
      gap: func.px2vw-lock(12, $desktopWidth)
      padding: func.px2vw-lock(20, $desktopWidth)

    .isLoggedInWrapper
      position: absolute
      top: 50%
      transform: translateY(-50%)
      display: flex
      flex-direction: row
      align-items: center
      padding-left: 1rem
      gap: .625rem

    .avatar
      width: func.px2vw-lock(40, $desktopWidth)
      height: func.px2vw-lock(40, $desktopWidth)

    .infoBlock
      display: flex
      flex-direction: column

      .text
        font-size: func.px2vw-lock(22, $desktopWidth)
        font-weight: bold

      .activity
        font-size: 1rem
        color: rgba(#fff, 0.7)

      &.isLoggedIn
        .text
          font-size: 1.375rem
          line-height: 1.15

    .memberDetailInfoArrow
      position: absolute
      top: func.px2vw-lock(34.37, $desktopWidth)
      right: 0
      width: 1.25rem
      height: 1.25rem
      transform: rotate(180deg)
      transform-origin: center center
      transition: all 0.5s ease-out

      &.reverse
        transform: rotate(0deg)

    .anchor
      position: absolute
      width: 100%
      height: 100%
      bottom: 0
      right: 0

    .tooltipContainer
      position: relative
      left: 0

  .placementBottom
    margin-top: 0.5rem

  .popper
    padding: 1rem 1rem
    z-index: 1200

  .card
    width: 14rem
    min-width: 170px
    *
      font-size: 1.125rem
      line-height: 1.125rem
      opacity: 1

    .content
      .column
        padding-top: 0.7rem
        padding-bottom: 0.7rem

        .pic
          height: 1.32rem
          padding-right: 0.5rem

      .desktopHidden
        display: none

    .bottom
      text-align: center

// mobile
@media only screen and (max-width: v.$mobileWidth)
  .root
    position: relative
    display: flex
    padding: 1rem

    .notLoggedInWrapper
      display: flex
      align-items: center
      gap: 0.625rem

    .isLoggedInWrapper
      display: flex
      flex-direction: row

    .infoBlock
      display: grid
      place-items: center

      .text
        font-size: 1.875rem

      .activity
        display: none

    .avatar
      width: 2.5rem
      height: 2.5rem
      margin: 0 0.4rem

    .memberDetailInfoArrow
      position: absolute
      bottom: -0.5rem
      left: 2rem
      width: 1.25rem
      height: 1.25rem
      transform: rotate(270deg)
      transition: all 0.5s ease-out

      &.reverse
        transform: rotate(90deg)

    .anchor
      position: absolute
      width: 1.25rem
      height: 1.25rem
      bottom: -1rem
      left: 1.25rem

  .card
    width: 22rem
    div
      font-size: 1.75rem
      opacity: 1

    .content
      .btn
        height: 3.5rem

      .column
        height: 3.5rem
        padding-top: 1rem
        padding-bottom: 1rem

        .pic
          height: 2rem
          padding-right: 0.5rem

    .bottom
      display: grid
      place-items: center
      height: 4rem
