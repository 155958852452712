@use 'theme/mobile/variables.module.sass' as theme

.toolbar
  width: 100vw
  min-height: 2rem
  display: flex
  padding: 0px
  height: theme.$headerHeight
  background: transparent linear-gradient(180deg, #1C59AA 0%, #020B30 100%) 0% 0% no-repeat padding-box
  .logoBlock
    flex-grow: 1
    justify-content: flex-start
    .logo
      position: absolute
      top: 0
      left: 2rem
      width: 11rem
  .menuBtn
    margin-right: 2.5rem
    width: 2.5rem
.card
  position: fixed
  top: theme.$headerHeight
  right: 5vw
  z-index: 1100
