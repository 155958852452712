@use 'theme/common/variables.module.sass' as v

// common
.paper
  background: transparent
  overflow: hidden
.root
  display: flex
  flex-direction: column
  background: #0E3E74
  box-shadow: 7px 10px 20px #00000080
  border-radius: 3.75rem
  width: 100%
  .title,
  .content,
  .functionFooter
    position: relative
    width: 100%
    display: flex
    justify-content: center
  .title
    color: #D9C998
    background: #002147 0% 0%
    z-index: 5
    width: 100%
    flex: 0 0 auto
    font-weight: bold
    display: flex
    justify-content: center
    align-items: center
    border-top-left-radius: 3.75rem
    border-top-right-radius: 3.75rem
  .content
    flex: 1
    overflow-x: hidden
    .scrollbarContent
      padding: 0 1rem
      .innerHtml
        padding: 1rem 0
    &::after
      content: ''
      width: 100%
      height: 2.25rem
      position: absolute
      left: 0
      z-index: 5
    &::after
      background: transparent linear-gradient(0deg, #02151D 0%, #02151D00 100%)
      bottom: 0
    .scrollbarFrame
      width: 100%
      position: relative
      .innerHtml
        overflow: hidden
        img
          max-width: 100%
  .functionFooter
    >.btn
      width: 50%
      &.confirm
        background: linear-gradient(180deg, lighten(#0B396E, 15%) 0%, #011327 100%)
        &:hover
          background: linear-gradient(180deg, lighten(#F3B336, 15%) 0%, #98570C 100%)
      &.cancel
        background: linear-gradient(180deg, #0B396E 0%, #011327 100%)
        &:hover
          background: linear-gradient(180deg, #F3B336 0%, #98570C 100%)
      &:disabled
        cursor: no-drop
      &.onlyConfirm
        width: 100%
        background: #0e3e74
        &:hover
          background: linear-gradient(180deg, lighten(#F3B336, 15%) 0%, #98570C 100%)

// desktop
@media only screen and (min-width: v.$mobileWidth+1)
  .paper
    position: absolute
    border-radius: 3.75rem
    min-width: 30%
    max-width: 50%
  .root
    .title
      padding: 1rem 3rem
      min-height: 5.375rem
      font-size: 1.875rem
    .content
      padding: 2rem
      max-height: 60vh
    .functionFooter
      height: 4.375rem
      .pageBtnGroup
        button
          background: transparent linear-gradient(180deg, #1679C4 0%, #032041 100%)
          box-shadow: 6px 30px 40px #00000029
          &:hover
            background: transparent linear-gradient(0deg, #1679C4 0%, #032041 100%)
      .closeBtn
        .closeText
          font-size: 1.125rem
// mobile
@media only screen and (max-width: v.$mobileWidth)
  .paper
    position: absolute
    border-radius: 1.625rem
    min-width: 90%
    max-width: 96% !important
  .root
    border-radius: 1.625rem
    .title
      padding: 1rem 2rem
      min-height: 8.375rem
      font-size: 2.25rem
      text-align: center
      border-top-left-radius: 1.625rem
      border-top-right-radius: 1.625rem
    .content
      padding: 2rem
      max-height: 60vh
    .functionFooter
      height: 5.625rem
      border-bottom-left-radius: 1.625rem
      border-bottom-right-radius: 1.625rem
      .pageBtnGroup
        button
          background: transparent
          padding: 0.5rem
          .btnText
            font-size: 1.5625rem
          &.nextBtn
            margin-left: 1rem
      .closeBtn
        .closeText
          font-size: 1.5625rem
