
.title
  letter-spacing: 1.2px
  color: #FFD83A
  opacity: 1
  font-size: 1.875rem
.text
  letter-spacing: 0.72px
  color: #FFFFFF
  opacity: 1
  font-size: 1.37rem
  padding: 1.5rem 0
.paper
  border-radius: 8px
  background: none
.root
  min-width: 35rem
  min-height: fit-content
  padding: 2rem 3rem
  display: flex
  flex-direction: column
  z-index: 9999 !important
  background: #031F37 0% 0% no-repeat padding-box
  border-radius: 8px
  opacity: 1
.btn
  width: fit-content
  border: 1px solid #FFFFFF
  border-radius: 6px
  padding: 0.5em 1rem
  background: #031F37 0% 0% no-repeat padding-box
  align-self: flex-end
  font-size: 1.125rem
  &:hover
    background: #FFFFFF1F 0% 0% no-repeat padding-box
    border: 1px solid #FFFFFF
    border-radius: 6px
    opacity: 1
