
@use '../../theme/mobile/theme.module.sass'

.root
  background: #072433
  min-height: 100vh
  .innerHtml
    padding: 2rem
    img
      max-width: 100% !important
