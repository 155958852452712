.paperWidthFalse
  width: 100%
  height: 100%
  display: flex
  background: none
  box-shadow: none
  justify-content: center
  overflow-y: initial
.root
  height: 100%
  min-width: 200px
  min-height: fit-content
  display: flex
  flex-direction: column