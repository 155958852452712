@use 'theme/common/variables.module.sass' as v
:local(a)
  color: #FFFFFF
.socialMediaBtn
  width: 210px
  padding: 5px
  cursor: pointer
  &:hover
    filter: brightness(1.2)
.forgetPasswordBtn
  margin-left: 5px
.checkInput label,
.checkInput input
  cursor: pointer

// mobile
@media only screen and (max-width: v.$mobileWidth)
  .socialMediaBtn
    width: 22.5rem
    padding: 0.25rem
  .forgetPasswordBtn
    right: 1rem
    position: absolute
