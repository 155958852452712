.root
  height: auto
  max-height: 100%
  width: 100%
  :global(.simplebar-scrollbar)
    &::before
      width: 100%
      left: 0
      background: transparent linear-gradient(180deg, #0DB9DE 0%, #0E71C9 23%, #004AA8 100%) 0% 0%
      border-radius: 0.5rem
      opacity: 1
  :global(.simplebar-track.simplebar-vertical .simplebar-scrollbar:before)
    top: 0
    bottom: 0
  :global(.simplebar-track)
    &:global(.simplebar-vertical)
      width: 0.8rem
      background: rgba(0,0,0,0.9)
      opacity: 1
      border-radius: 0.5rem
      z-index: 99
