$fontSize: 1.875rem
$fontFamily: Verdana, Microsoft JhengHei UI
$textColor: #ffffff
$primaryColor: #598cdd
$secondaryColor: #73a092
$errorColor: #cb492a
$headerHeight: 5rem
$appBarZIndex: 1400
$bottomNavigationHeight: 6.25rem

:export
  fontSize: $fontSize
  textColor: $textColor
  primaryColor: $primaryColor
  secondaryColor: $secondaryColor
  errorColor: $errorColor
  appBarZIndex: $appBarZIndex
