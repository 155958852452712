@use 'theme/common/deviceSize.module.sass' as device

@keyframes slideInFromBottom
  0%
    opacity: 0
    transform: translateY(100%)
  60%
    opacity: 0
    transform: translateY(100%)
  100%
    opacity: 1
    transform: translateY(0)

@keyframes slideInFromBottomDelay
  0%
    opacity: 0
  100%
    opacity: 0

.root
  position: absolute
  .csChat + div[class^=fb_dialog]
    :global(.fb_dialog_content)
      >iframe[name^=blank_]
        +device.desktop
          right: 7rem !important
          bottom: 1rem !important
          z-index: 1499 !important
          animation: slideInFromBottom 1s ease-out 0s 1 normal none running !important
          opacity: 1
        +device.mobile
          right: 2rem !important
          bottom: 7rem !important
          margin: 0 0.3rem !important
          z-index: 1499 !important
          animation: slideInFromBottom 1s ease-out 0s 1 normal none running !important
          opacity: 1
      >iframe[name^=unread_]
        +device.desktop
          right: 7.5rem !important
          bottom: 4rem !important
        +device.mobile
          right: 2.5rem !important
          bottom: 10rem !important
  .vague
    filter: blur(12px)
