
@use './variables.module.sass' as v
@use '../common/variables.module.sass' as cVrbs
@media screen and ( max-width: cVrbs.$mobileWidth )
  :global(html)
    font-size: calc(100vw / 46.875)
  :global(body)
    font-family: v.$fontFamily
    background-color: #031c38
  :global(.error)
    font-size: 1.875rem
  div,button
    :global()
      font-size: v.$fontSize
      color: v.$textColor
      outline: none
      outline-width: 0
  :global(.MuiDialog-paper)
    margin: 0
  :global(.MuiDialog-paperWidthFalse)
    max-width: none
  :global(.MuiTooltip-tooltip)
    max-width: none
  :global(.MuiAppBar-root)
    z-index: v.$appBarZIndex
  input[type=checkbox]
    :global()
      width: 1.875rem
      height: 1.875rem
  :global(.grecaptcha-badge)
    visibility: hidden
  :global(.MuiFormGroup-root)
    flex-wrap: nowrap
