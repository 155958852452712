
@use './variables.module.sass' as v

@media screen and ( min-width: 981px )
  :global(html)
    font-size: clamp( 8px, calc(100vw / 120), 16px)
    overflow: overlay
  :global(body)
    padding: 0 !important
    font-family: v.$fontFamily
  :global(.error)
    font-size: 16px
  div,button
    :global()
      font-size: v.$fontSize
      color: v.$textColor
  ::selection
    background: #0b5561
  ::-moz-selection
    background: #0b5561
  :global(.grecaptcha-badge)
    visibility: hidden
