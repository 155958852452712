$cYoutube: #F40000
$cFacebook: #1773EA
$cLine: #07BC02

.root
  width: 100%
  height: auto
  display: flex
  justify-content: space-between
  align-items: center
  .youtube,
  .facebook,
  .line
    flex: 1 1 auto
    width: 2.2rem
    height: 2.2rem
    border-radius: 50%
    padding: 0.1rem
    position: relative
    >img
      filter: drop-shadow(0px 3px 6px #00000082)
      width: 58%
      max-height: 58%
      display: block
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
  .youtube
    background: $cYoutube
    &:hover
      background: rgba($cYoutube, 0.6)
  .facebook
    background: $cFacebook
    &:hover
      background: rgba($cFacebook, 0.6)
  .line
    background: $cLine
    &:hover
      background: rgba($cLine, 0.6)
